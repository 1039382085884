.card-labels {
    color: black;
    font-size: 14px;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  
   #tsep-cardNum {
    border: none; /* Remove the default border */
    border-bottom: 1px solid white; /* Add a 1px solid black border at the bottom */
    background: transparent; /* Make the background transparent */
    outline: none;
    color: currentColor !important;
    font-size: medium;
    width: 100%;
    padding-right: 12px;
  }
  
   #tsep-cardNum::placeholder {
    color: currentColor;
  }

  #tsep-cardContainer {
    padding-bottom: 23px;
  }

  #tsep-CardData {
    display: flex;
    justify-content: space-between;
  }
  
  #tsep-cvv2 {
    background: transparent; /* Make the background transparent */
    outline: none;
    color: currentColor !important;
    font-size: medium;
    width: 100%;
    border-bottom: 2px dashed white;
  }
  
  #tsep-cvv2::placeholder {
    color: currentColor;
  }

  #tsep-datepicker {
    border: none; /* Remove the default border */
    border-bottom: 1px solid white; /* Add a 1px solid black border at the bottom */
    background: transparent; /* Make the background transparent */
    outline: none;
    color: currentColor !important;
    font-size: medium;
    width: 100%;
    padding-right: 12px;
  }
  
  #tsep-datepickerDiv input::placeholder {
    color: currentColor !important;
  }