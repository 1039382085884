.App_header {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: black; */
}
.payBtn{
  color: white !important;
  background:green !important;
  border-radius: 50px !important;
  margin-bottom: 12px !important;
  width: 100% !important;
 }
.invoiceDetail{
margin-top:2.5rem;
}
.cardNum{
width: 34rem;
  height: 3rem;
  letter-spacing: 1.65rem;
  padding: 18.5px 14px;
}
.expDate{
width: 10rem;
height: 3.3rem;
padding: 0px 1rem;
}
.curser{
color: auto;
}
.curser:hover{
cursor: pointer;
}
.cardCvv{
width: 5rem;
height: 3.3rem;
padding: 0px 1rem;
}
.viewInvoice{
border-radius: 50px !important;
width:100%;
}

.bannerText {
  font-size: 18px;
  font-weight: 400;

}

@media (min-width: 768px) {
  .exp_Cvv{
    display: flex;
    flex-direction: row;
    padding-top: 20px;
  }
}

@media only screen and (max-width:480px){
.cardNum{

  letter-spacing:0.4rem;
  padding: 8.5px 4px;
}
}
