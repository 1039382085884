.App_header {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    /* color: black; */
  }
  .body_section{
    min-height:calc(100vh - 44px);
    margin-top: 0px;
    background: #fafafa;    
    margin-top: 44px;
}
 
.left_nav{
    width: 260px;    
    height: 200px;
    float: left;
}
.left_nav ul{
    margin: 0;
    padding: 0;
}
.left_nav ul li {
    list-style: none;
    padding: 20px;    
}
.left_nav ul li a{
    color:#000;
    font-size: 16px;
    padding: 5px;
}
.left_nav ul li a i{
    vertical-align: top;
    font-size: 28px;
    margin-right: 10px;
}
.body_content_section{   
    /* padding: 20px; */
    float: left;
    width: calc(100% - 270px);    
}
.body_content_section .wht_box{
    background: #fff;
    padding: 30px; 
    padding-top: 0;  
    -webkit-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.21);
    -moz-box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.21);
    box-shadow: 0px 6px 5px -2px rgba(0,0,0,0.21);   
    border-radius: 10px;
}
.mySnackbarRed {
    background: #E9454C !important;
    font-weight: bold !important;
    margin:0 1rem 1rem !important;
}
.mySnackbarBlue {
    background: #157FFB !important;
    font-weight: bold !important;
    margin:0 1rem 1rem !important;
}
h2.form_heading {
    font-size: 20px;
    padding: 5px 5px 5px
}
.invoice_wth .MuiGrid-root.MuiGrid-item {
    max-width: 100%;
    flex-basis: 100%;
    padding-right: 1rem;
}
.MuiPaper-root h2 {
    text-align: left;
    padding-left:1rem;
}
.mt_16 {
    margin-top: 16px !important;
}
.mt_30 {
    margin-top: 30px !important;
}
.mb_8{
    margin-bottom: 8px !important;
}
.ml_1{
    margin-left: 1rem !important;
}
.pl_1{
    padding-left: 1rem !important;
}
 
.pb_1{
    padding-bottom: 1rem !important;
}
.pt_1{padding-top: 1rem;}

.w_100 {
    width: 100% !important;
}
.text_left {
    text-align: left;
}
