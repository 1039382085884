.nav_width_65 {
    width: 100%;
  }
  .ml_2 {
    margin-left: 1rem !important;
  }
  .emailInvoice {
    margin-right: 1rem !important;
    background-color: #e27329 !important;
  }
  .mr_2 {
    margin-right: 1rem;
  }
  .cursorPointer {
    cursor: pointer;
  }
  
  .modelWrapper {
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%; -50%)';
    width: '90%';
    background-color: 'white';
    border: '2px solid #000';
    box-shadow: 24;
    justify-content: 'center';
    align-items: 'center';
    //   p: 4;
  }
  
  .surchargeWarning {
    color: blue;
    font-size: 11px;
  }
  
  .paylink {
    background-color:#e27329!important;
  }
  
  .grid{
    margin-top: 10px;
  }
  @media (max-width: 1024px) {
    .grid{ 
      margin-top: 10px !important;
    }
  }
  
  @media (max-width: 768px) {
    .paylink{ 
      width: 100%;
      margin-bottom: 25px !important;
      margin-left: 0px !important;
    }
  }
  
  @media (max-width: 600px) {
    .modelWrapper {
      width: 90% !important; /* Set a custom width for small screens */
    }
  }
  
  @media (min-width: 601px) and (max-width: 960px) {
    .modelWrapper {
      width: 70% !important; /* Set a custom width for medium screens */
    }
  }
  
  @media (min-width: 961px) {
    .modelWrapper {
      width: 50% !important; /* Set a custom width for large screens and above */
    }
  }
  
  .paylinkBtn {
    margin-left: 10px !important;
    background-color:#e27329!important;
  }
  .dualFeePaylink{
    margin-left: 10px !important;
    background-color:#e27329!important;
  }
  
  .textInvoiceBtn {
    margin-right: 10px !important;
    background-color: #03af38 !important;
    color: #000;
  }
  
  .chargeCard{
    margin-left: 0px !important;
  }
  .backbtn{
    margin-left: 0px !important;
  }
  
  .generatepaylink {
    background-color:#fff;
  }
  .terminalSelect{
    position: relative;
    margin: 0;
  }
  
  @media (max-width: 360px) {
    .terminalSelect{
      margin-bottom: 20px !important;
    }
    .manualcheckbox{
      margin-bottom: 20px !important;
    }
  }
  
  @media (max-width: 600px) {
    .paylinkBtn {
      width: 30.5% !important; 
      margin-left: 8px !important;
      margin-bottom: 5px !important;
      background-color:#477ab0!important;
      font-size: 12px !important;
      color: #fff !important;
      font-weight: 550 !important;
    }
    .generatepaylink{ 
      width: 100%;
      margin-bottom: 20px !important;
      margin-left:0px !important;
    }
    .dualFeePaylink{
      width: 45% !important; 
      margin-left: 11px !important;
      margin-right: 0px !important;
      margin-bottom: 5px !important;
      background-color:#477ab0!important;
      font-size: 12px !important;
      color: #fff !important;
      font-weight: 550 !important;
    }
  
    .textInvoiceBtn {
      width: 45% !important; 
      margin-bottom: 15px !important;
      margin-right: 11px !important;
      background-color:#477ab0!important;
      font-size: 12px !important;
      color: #fff !important;
      font-weight: 550 !important;
    }
  
    .chargeCard{
      width: 100% !important;
      margin-bottom: 15px !important;
      background: #5fdbac !important;
      font-size: 20px !important;
      font-weight: 600 !important;
    }
  
    .backbtn{
      width: 100% !important;
      margin-bottom: 10px !important;
      background: #f52552 !important;
      font-size: 15px !important;
      font-weight: 550 !important;
    }
    .cashbtn{
      width: 100% !important;
      margin-bottom: 10px !important;
      background: #477ab0 !important;
      color: #fff !important;
      font-size: 15px !important;
      font-weight: 550 !important;
    }  
    .terminalSelect{
      margin-bottom: 20px !important;
    }
    .manualcheckbox{
      margin-bottom: 15px !important;
    }
    .mobileDisableBtn {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .cashModal{
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  @media (max-width: 767px) {
    .cashModal{ 
      width: 100% !important;
      margin-bottom: 10px !important;
      margin-left: 4px !important;
      font-size: 16px !important;
    }
  }
  
  //  [class*='TypableSelectDetails-input']{
  //      height:2rem !important;
  //  }
  //  [class*='TypableSelectDetails-paper']{
  //     z-index:9 !important;
  // }
  // [class*='TypableSelectDetails-placeholder']{
  //     font-size: 1em !important;
  //     padding-bottom: 3px !important;
  // }
  
