* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }
  
  .drop_box {
    width: 100%;
    margin: 10px 0;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px dotted #a3a3a3;
    border-radius: 5px;
  }
  
  .drop_box h4 {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
  }
  
  .drop_box p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #a3a3a3;
  }
  
  .file-upload-wrapper {
    position: relative;
    width: 100%;
    height: 60px;
  }
  
  .file-upload-wrapper:before {
    content: 'Select File';
    display: inline-block;
    background: #4daf7c;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 60px;
    padding: 0 15px;
    text-transform: uppercase;
    pointer-events: none;
    border-radius: 5px 5px 5px 5px;
    text-align: center;
    width: 50%;
    align-self: center;
  }
  .file-upload-wrapper:hover:before {
    background: #3d8c63;
  }
  .file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 40px;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }
  .bulkInvoicesModal{
    position: absolute;
    top: 5%;
    bottom: 10%;
    left: 10%;
    right: 10%;
    transform: 'translate(-50%, -50%)';
    height: 90%;
    padding: 25px;
    background: #fff;
    box-shadow: 24;
    overflow: auto;
}
.csv-table {
  border-collapse: collapse;
  width: 100%;
}
.csv-table th, .csv-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.csv-table th {
  background-color: #f2f2f2;
}
.bulk_table_display {
  width: 100%;
  margin: 10px 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  border: 3px dotted #a3a3a3;
  border-radius: 5px;
  height: inherit;
  overflow-x: scroll;
}
.bulk_table_display h4 {
  font-size: 16px;
  font-weight: 400;
  color: #2e2e2e;
}

.bulk_table_display p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #a3a3a3;
}