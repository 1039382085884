.textAlignLeft {
    text-align: left;
}

.textAlignCenter {
  text-align: center;
}

.textAlignRight {
  text-align: right;
}

.paymentInfo {
  display: flex;
  justify-content: end;
  column-gap: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.rightSection {
  &:before {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    background-color: #000000;
  }

  display: flex;
  column-gap: 20px;
  div {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
}

.leftSection {

    display: flex;
    flex-direction: column;
    row-gap: 5px;

}