img {
  max-width: 100%;
  /* // width: 100%; */
}

.container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  z-index: 1;
  margin-top: 10px;
}
.center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.contact-form {
  background-color: rgb(255, 255, 255);
  padding: 0px;
  margin: 0px !important;
}

.contact-form-container {
  margin-bottom: 100px;
  margin-top: 50px;
}

.contact-form-header {
  font-size: xxx-large;
  color: #353535;
  text-align: center;
  letter-spacing: 4px;
  margin: 5px;
}

.contact-form-subheader {
  font-size: 15px;
  color: #565555e3;
  text-align: center;
  margin: 5px;
}

.form-input-container {
  padding: 20px;
}

header.headerPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 15px 0;
}
.topmenu > ul > li {
  float: left;
  margin-right: 30px;
}
.topmenu > ul > li > a {
  font-size: 20px;
  color: #000;

  text-transform: uppercase;
}
.topmenu > ul > li:hover > a {
  color: #179fd3;
}
.topmenu > ul > li:last-child {
  margin-right: 0px;
}
.bannerWrapper {
  /* background: url("https://titaniumflex.app/assets/images/latest-gif.gif") no-repeat; */
  width: 100%;
  background-size: cover;
  min-height: 600px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
}
.bannerHeading {
  font-size: 60px;
  margin: 0;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 10px;
}
.bannerHeading > span {
  display: block;
}

.footerWrapper {
  background: #0a6691;
  padding: 20px;
  text-align: center;
}
.footerWrapper > a {
  margin: 0;
  color: #fff;
}
.alert-danger {
  color: rgba(255, 0, 0, 0.864);
}

.m-0 {
  margin: 0px !important;
}
._loading_overlay_wrapper.css-79elbk {
  padding: 38px 0 !important;
  margin-top: 0px !important;
  box-shadow: 1px 1px 9px #d4d4d4 !important;
}

@media (max-width: 991px) {
  .container {
    position: relative;
    z-index: 1;
  }
  .bannerWrapper {
    /* position: absolute; */
    z-index: 0;
    min-height: 220px;
  }
  .contact-form-container {
    margin-bottom: 100px;
    margin-top: 50px;
  }
}

@media (max-width: 567px) {
  .topmenu {
    width: 100%;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 5px;
    padding-top: 15px;
  }
  .topmenu > ul > li {
    float: none;
    display: inline-block;
  }
}

.expendColor a {
  color: #1896d5;
  font-weight: bold;
}
.videobox {
  position: absolute;
  top: 0;
  z-index: -1;
}

.videobox video {
  width: 100%;
  position: fixed;
}

.flexOptionWrapper {
  margin-bottom: 35px;
}

#root {
  padding-bottom: 0;
}

#root > div {
  /* position: relative; */
  min-height: 100%;
}

.footerWrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.yt-video {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}

@media (max-width: 768px) {
  .bannerWrapper {
    position: relative;
  }

  .videobox video {
    position: initial;
  }
  .bannerHeading {
    font-size: 52px;
    letter-spacing: 1px;
  }
}
