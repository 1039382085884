.nav_width_65 {
  width: 100%;
}
body .userLink {
  width: 100%;
  height: 100%;
  position: absolute;
}
.paper {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
.avatar {
  margin: 1px;
}
.form {
  width: 100%;
  margin-top: 1px;
}
.error {
  font-size: 30px;
  color: red;
}
.ml_2 {
  margin-left: 1rem !important;
}
.preview {
  height: 4rem;
  width: 10rem;
}
.hide {
  display: none;
  text-align: center;
}
.show {
  display: block;
  text-align: center;
}
.contentPart {
  padding: 1rem;
}
.withError {
  width: '100%';
  border: 2px;
  color: red;
  height: 100px;
}
.removeError {
  width: '100%';
}
.rightImg {
  padding: 0px 10px;
}
.accordionMui {
  &:before {
    background-color: white !important;
  }
}
