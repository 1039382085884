.transactionType{
    color: #3f51b5;
    padding:20px ;
    margin-left: -71px;
    width: auto;
}

.header-container{
    height: 125px;
    margin-top: -35px;
    padding-left: 27px;
}