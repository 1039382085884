.Appheader {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }
  .App {
    text-align: center;
  } 
/*------ request-page ---*/

.requestformpage .adminBtn{
    background: #4CAF50 !important;
}

.surchargeWarning {
    color: blue;
    font-size: 11px;
  }

.requestformpage{
    background: #fafafa !important;
}
.requestformpage .MuiPaper-root{
    margin-bottom: 60px !important;
}

.requestformpage .saveforLetterBtn{
    background: #4CAF50 !important;
    color:#000 !important;
}

.requestformpage .sendPayment{
    background: #9c27b0 !important;
}


 
.top-header {
    display: inline-block;
    text-align: right;
    width: 100%;
    margin-top: 15px;
}
.logout-img{
    width:18px;
    height: 18px;
    margin-left:0px;
    margin-top:6px;
    float: right;
}
.topheader {
    display: inline-block;
    text-align: right;
    width: 100%;
    margin-top: 15px;
}
.logoutimg{
    width:18px;
    height: 18px;
    margin-left:0px;
    margin-top:6px;
    float: right;
}
.MuiButton-containedSecondary{
    background-color: #4CAF50 !important;
  }
  .containerControl{
      max-width: 980px;
      width:100%;
      margin-bottom: 50px;
  }
  .table{
    background-color: #4CAF50 ;
  }
  .newClass{
    background-color: #4CAF50 ;
  }
  .ml_2
  {
      margin-left: 1rem !important;
  }
 
  .Underline{
    color :blue;
    cursor:pointer
}
 .addNewClient{
    margin-top: 10px !important;
    background-color: #117CC1 !important;
 }
 .submit{ 
    background-color: #117CC1 !important;
 }
 .saveDraft{ 
    margin-left: 1rem !important;
    background-color: #adb2b5 !important;
 }
 .emailInvoice{ 
    margin-right: 1rem !important;
    background-color: #e27329 !important;
 }

 .itemHead{min-width:110px;}

 .pt6{
    padding-top: 2.5rem !important;
    padding-bottom: 1.2rem !important;
 }
 .unitCost3{width: 5rem  !important;}

.updatemodal{
    margin-right: 10px !important;
    margin-left: 10px !important;
}
@media (max-width: 834px) {
  .updatemodal{ 
    width: 100% !important;
    margin-bottom: 10px !important;
    margin-left: 4px !important;
    font-size: 16px !important;
  }
}
@media (min-width: 800px) and (max-width: 1023px) {
    .pt6{
        padding-top: 3rem !important;
        padding-bottom: 1.2rem !important;
    }
}
@media (max-width: 768px) {
    .payment_btn{
        width: 45% !important;
        margin-bottom: 15px !important;
        margin-left: 13px !important;
        background: #5fdbac !important;
        font-size: 15px !important;
        font-weight: 550 !important;
        color: #fff !important;
    }  
    .onlycard_btn{
        width: 95% !important;
        margin-bottom: 15px !important;
        margin-left: 10px !important;
        background: #5fdbac !important;
        font-size: 15px !important;
        font-weight: 550 !important;
        color: #fff !important;
    }
    .email_btn{
        width: 45% !important;
        margin-bottom: 15px !important;
        margin-left: 13px !important;
        background: #477ab0 !important;
        font-size: 12px !important;
        font-weight: 550 !important;
        color: #fff !important;
    }  
    .draft_print_btn{
        width: 45% !important;
        margin-bottom: 15px !important;
        margin-left: 13px !important;
        background: #477ab0 !important;
        font-size: 15px !important;
        font-weight: 550 !important;
        color: #fff !important;
    }
    .backbtn{
        width: 97% !important;
        margin-left: 6px !important;
        margin-bottom: 10px !important;
        background: #f52552 !important;
        font-size: 15px !important;
        font-weight: 550 !important;
        
    }
    .continue{
        width: 100% !important;
        margin-left: 3px !important;
        margin-bottom: 10px !important;
        background: #f52552 !important;
        font-size: 15px !important;
        font-weight: 550 !important;
        color: #fff !important;
    }
}
@media (max-width: 768px) {
    .pt7{
        margin-top: -60px !important;
    }
}
 