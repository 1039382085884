body .login {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: #f7f8fa;
}
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.logoLink {
	width: 50%;
}
.heading {
	font-size: 40px;
	font-weight: 700;
	line-height: 48.41px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #152534;
}
.paper {
	margin-top: 35px;
	display: flex;
	flex-direction: column;
	gap: 36px;
	background-color: #ffffff;
	border-radius: 12px;

	padding: 64px;
}
.avatar {
	margin: 1px;
}
.form {
	width: 100%;
	margin-top: 1px;
}
.formTextfield {
	background-color: #f0f2f3;
	border-radius: 8px;
	padding: 0px 5px 0px 5px;
}
.submit {
	width: 100%;
	margin-top: 36px !important;
	border-radius: 100px !important;
	padding: 15px 36px 15px 36px !important;
	background-color: #20bdbe !important;
	box-shadow: none !important;
}

.forget {
	width: 70%;
	float: left;
	margin: 5px 0 0 0 !important;
	color: #20bdbe !important;
	font-size: 14px;
	font-weight: 500;
	line-height: 16.94px;
	text-align: left;
}

.back {
	width: 20%;
	float: right;
	text-align: right;
	margin: 5px 0 0 0 !important;
}

.footer {
	margin-top: 160px;
	margin-bottom: 30px;
	font-size: 12px;
	font-weight: 400;
	line-height: 19.6px;
	text-align: center;

	.phone {
		color: #20bdbe !important;
		font-weight: 600;
	}

	.mail {
		color: #20bdbe !important;
		font-weight: 600;
	}
}
