.pt_1 {
  padding-top: 1rem;
}
.ml_2 {
  margin-left: 1rem !important;
}
.btn_space{
  margin-right: 1rem !important;
}


.accordionMui {
  &:before {
    background-color: white !important;
  }
}
