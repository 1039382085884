@media(min-width:1025px) and (max-width:1440px)
{
    .tabledate{
        margin-left: 15px !important;
        margin-right: 15px !important;
}}
@media(min-width:769px) and (max-width:1024px)
{
    .tabledate{
    margin-left: 17px !important;
    margin-right: 17px !important;
}}

 