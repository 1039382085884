.nav_width_65 {
  width: 100%;
}
body .userLink {
  width: 100%;
  height: 100%;
  position: absolute;
}
.paper {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
.avatar {
  margin: 1px;
}
.form {
  width: 100%;
  margin-top: 1px;
}
.error {
  font-size: 20px;
  color: red;
  padding-bottom: 10px;
}
.success {
  font-size: 20px;
  color: green;
  padding-bottom: 10px;
}
.ml_2 {
  margin-left: 1rem !important;
}
.preview {
  height: 4rem;
  width: 10rem;
}
.hide {
  display: none;
  text-align: center;
}
.show {
  display: block;
  text-align: center;
}
.contentPart {
  padding: 1rem;
}
.withError {
  width: '100%';
  border: 2px;
  color: red;
  height: 100px;
}
.removeError {
  width: '100%';
}
.rightImg {
  padding: 0px 10px;
}
.accordionMui {
  &:before {
    background-color: white !important;
  }
}
.signupForm {
  display: flex;
  flex-direction: column;
}

.signupFormContainer {
  max-width: 430px;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.formH3 {
  white-space: nowrap;
  padding: 6px 0
}
.signUpInput {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.formGroup {
  margin-bottom: 20px;
}
.labelSignup {
  font-weight: bold;
}
.errorHighlight {
  border-color: #ff0000;
}
.errorMessage {
  color: #ff0000;
  font-size: 14px;
  margin-top: 4px;
}
.submitButton {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}
.submitButtonTopper {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 30px !important;
}
.submitButton:hover {
  background-color: #0056b3;
}