.nav_width_65{
    width: 100%;
}
.ml_2
{
    margin-left: 1rem !important;
}
.successMsg{
    color: green;
}
.Underline{
    color :blue;
    cursor:pointer
}

.formBox label{
    color: #333;
    opacity: 1;
}
.emailInvoice{
    margin-left: 1rem !important;
    background: #e27229 !important
}

.updatedRow {
    margin-left: 70px;
}

.labelDate {
    position: relative;
    top: 42px;
}

.buttonSearch {
    position: relative;
    top: 41px;
    left: 17px;
    margin-right: 10px;
}