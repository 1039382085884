.bulkInvoicesModal{
    position: absolute;
    top: 5%;
    bottom: 10%;
    left: 10%;
    right: 10%;
    transform: 'translate(-50%, -50%)';
    height: 90%;
    padding: 25px;
    background: #fff;
    box-shadow: 24;
    overflow: auto;
}
.btnBulk{
    display: flex;
    justify-content: flex-end;
}
.btnBulk .txtBtn{
  background-color: #03af38 !important;
}
.btnBulk .emailBtn{
    background-color: #e27329 !important;
    margin-right: 20px;
}
  
  .formControlLabel {
    flex: 1 0 30%;
  }

@media (max-width: 767px) {
    .bulkInvoicesModal{
        left: 5%;
        right: 5%;
        font-size: '0.8rem';
        overflow: auto;
    }
    .btnBulk{
        flex-direction: column;
        align-items: flex-end;
    }
    .btnBulk .txtBtn{
    width: 100%;
    margin: 5px 0;
  }
    .btnBulk .emailBtn {
        width: 100%;
        margin: 5px 0;
        margin-right: 0; 
    }
      .formControlLabel {
        flex: 1 0 50%;
        margin-bottom: 10px;
      }
}
@media (min-width: 768px) and (max-width: 1023px) {
    .bulkInvoicesModal{
        left: 5%;
        right: 5%;
        height: 75%;
        font-size: '0.8rem';
        overflow: auto;
    }}

