 

body{
    font-family:  'Montserrat', 'sans-serif';
    font-size: 14px;
    color: #000;
    background: #f8f8f8;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
h1, h2, h3, h4, h5, h6{
    margin: 0;
}
a{
    text-decoration: none;
}
p {
    font-size: 14px;
    line-height: 22px;
    color: #4a4a4a;
    margin: 0;
    padding: 0;
    white-space: normal; 
}
img {
    max-width: 100%;
    // width: 100%;
}
.headerPanelContainer{
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    z-index: 1;
    overflow: hidden;
}
.container {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    z-index: 1;
}
header.headerPanel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.topmenu {
    margin-right: 100px;
}
.topmenu > ul > li{
    float: left;
    margin-right: 30px;
}
.topmenu > ul > li > a{
    font-size: 20px;
    color: #000;
    
    text-transform: uppercase;
}
.topmenu > ul > li:hover > a{
    color: #179fd3;
}
.topmenu > ul > li:last-child {
    margin-right: 0px;
}
.bannerWrapper{
    // background: url("https://titaniumflex.app/assets/images/latest-gif.gif") no-repeat;
    width: 100%;
    background-size: cover;
    min-height: 600px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
}
.bannerHeading{
    font-size: 100px;
    font-family: 'Montserrat', 'sans-serif';
    margin: 0;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 10px;
}
.bannerHeading > span{
    display: block;
}
.infoWrapper {
    display: block;
    width: 100%;
    position: relative
}
.infoContentBox {
    display: flex;
    flex-wrap: wrap;
    border: 3px solid #2799d6;
    position: relative;
    top: -90px;
    background: #fff;
}
.infoContentBox .infoLeftPanel,
.infoContentBox .infoRightPanel{
    flex: 0 0 50%;
    padding: 15px;
    box-sizing: border-box;
}
.infoLeftPanel h2,
.infoLeftPanel p{
    margin: 0 
}
.infoDetails h2{
    margin: 0;
}
.infoDetails {
    margin: 15px 0;
}
.flexOptionWrapper{
    width: 100%;
    display: block;
}
.flexOptionWrapper > h1 {
    text-align: center;
    padding: 15px;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 45px;
    color: #1796d5;
    font-weight: 800;
}
.choosePlanWrapper{
    display: flex;
    flex-wrap: wrap;
}
.choosePlanWrapper > .block{
	flex: 0 0 33.333%;
	max-width: calc(33.333% - 15px);
    margin-right: 15px;
    background: #fff;
    margin-bottom: 15px;
    border-bottom: 5px solid #0a6691;
    border-radius: 10px 10px 10px 10px;
}
.choosePlanWrapper > .block:nth-child(3n){
	margin-right: 0;
}
.headSection.blueBg{
    background: #1796d5;
}
.headSection.darkBlue{
    background: #0a6691;
}
.headSection.skyBlue{
    background: #33c0c8;
}
.headSection > h2{
    padding: 15px;
    color: #fff;
    letter-spacing: 2px;
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;
}
.contentBox{
    background: #fff;
    padding: 15px;
    text-align: center;
    height: auto;
    // min-height: 450px;
    position: relative;
}
.paymentDetails > h2{
    text-align: center;
    color: #1796d5;
}
.pricetxt {
    font-size: 40px;
}
.dollortxt,
.decimal-txt {
    position: relative;
    top: -22px;
    font-size: 24px;
}
.permonthtxt{
    font-size: 22px;
    color: #1796d5;
    font-weight: 800;
    letter-spacing: 2px;
    text-align: center;
}
.paymentDetails {
    margin: 20px 0;
}
.listItem {
    text-transform: capitalize;
    width: 80%;
    margin: 20px auto;
    border-top: 1px solid #1796d5;
    padding-top: 15px;
    margin-top: 25px;
}
.listItem > li {
    text-align: left;
    line-height: 30px;
    font-size: 16px;
}
.contentBox::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
    background: #1796d5;
    left: 0;
    bottom: 0;
}
.footerWrapper{
    background: #0a6691;
    padding: 20px;
    text-align: center;
}
.footerWrapper > a{
    margin: 0;
    color: #fff;
}

@media (max-width:991px){
	.choosePlanWrapper > .block{flex:0 0 50%;max-width: calc(50% - 15px);}
	.choosePlanWrapper > .block:nth-child(3n){
		margin-right: 15px;
	}
	.choosePlanWrapper > .block:nth-child(2n){
		margin-right: 0;
    }
    .container{
        position: relative;
        z-index: 1;
    }
    .headerPanelContainer{
        padding: 0 0px;
    }
    .bannerWrapper{
        position: absolute;
        z-index: 0;
        min-height: 220px;
    }
    .infoContentBox{
        top: 0;
    }
}

@media (max-width:567px){
	.infoContentBox .infoLeftPanel,
	.infoContentBox .infoRightPanel{
		flex:0 0 100%;
	}
	
	.topmenu{
		width: 100%;
		text-align: center;
		border-top: 1px solid rgba(0, 0, 0, 0.2);
		margin-top: 5px;
        margin-right:0px;
		padding-top: 15px;
        display: flex;
        justify-content: center;
	}
	.topmenu > ul > li{
		float: none;
		display: inline-block;
	}
	.choosePlanWrapper > .block{flex:0 0 100%;max-width: 100%;margin-right: 0 !important;}
}
.listItemInner{
    display: none
}
.listItemInnerBlock{
    display: block
}
.expendColor a{
    color: #1896d5;
    font-weight: bold;
}
.videobox{
    position: absolute;
    top: 0;
    z-index: -1;
}

.videobox video{
    width: 100%; 
    position:fixed;   
}

.flexOptionWrapper{
    margin-bottom: 35px;
}

#root {
    padding-bottom: 0;
}

#root > div{
    // position: relative;
    min-height: 100%;
}

.footerWrapper{
    position: relative;
    bottom: 0;
    width: 100%;
}


@media(max-width: 768px){
    .bannerWrapper{
        position: relative;
        margin-top: 0px;
        margin-right: 20px;
        overflow: hidden;
    }
    .bannerHeading{
        font-size: 80px;
        margin: 0;
        text-align: center;
        letter-spacing: 5px;
    }

    .videobox video{
        position: initial;   
    }
    header.headerPanel{
        position: relative;
    }
}
.logo{
    margin-left: 70px;
    margin-top: 10px;
    margin-bottom: 10px; 
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
@media (max-width: 567px) {
    .logo {
        margin: 10px auto;
    }
}