	body .forgotPassword {
	  background:url('./../../assets/images/loginImage.jpg');
	  width: 100%;
      height: 100%;
      position: absolute;
	}
	.paper {
	   margin-top: 100px;
	   display: flex;
	   flex-direction: column;
	   align-items: center;
	   background-color:white;
	   padding:20px;
	   border-radius:5px;
	}
	.paperWhite {
		margin-top: 100px;
		display: flex;
		flex-direction: column;
		background-color:white;
		padding:20px;
		border-radius:5px;
	 }
    .avatar {
	    margin: 1px;
    }
    .form {
		width: 100%;
		margin-top: 1px;
	}
	.text_right{
		text-align: right;
	}
	
	@media(min-width:600px){
		.max_Width{
		  max-width: 500px;
		}
		.ErrorMessage_errors__20g1F{
		  width: 50%;
		}
		}
		.max_Width{
			max-width: 500px !important;
		}

	.usernames > span {
		padding: 8px;
		display: block;
		background: #e2e7ec9e;
		color: black;
		margin: 5px;
		width: 100%;
		cursor: pointer;
	}
	.mu {
		margin-top: 10px;
	}