.card-header {
  border-bottom: 1px solid #056eb1;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  justify-content: space-between;
}

.card-details0 #card-label-id {
  color: #a9daf9;
  font-size: 14px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.tsep-cardNumDiv :hover{
  padding-top: 6px;
}

.tsep-cardNumDiv #tsep-cardNum {
  border: none;
  border-bottom: 1px solid white;
  background: transparent;
  outline: none;
  color: white !important;
  font-size:14px;
  width:105%;
  max-width:162px

}

.tsep-cardNumDiv #tsep-cardNum::placeholder {
  color: transparent;
}


.tsep-datepickerDiv :hover {
  padding-top: 6px;
}
.tsep-datepickerDiv #tsep-datepicker {
  border: none;
  border-bottom: 1px solid white;
  background: transparent;
  outline: none;
  color: white !important;
  width: 100%;
  max-width: 88px;
  font-size:14px
}

#tsep-datepickerDiv input::placeholder {
  color: white;
}

.tsep-cvv2Div :hover {
  padding-top: 6px;
}

.tsep-cvv2Div #tsep-cvv2 {
  border: none; /* Remove the default border */
  border-bottom: 1px solid white; /* Add a 1px solid black border at the bottom */
  background: transparent; /* Make the background transparent */
  outline: none;
  color: white !important;
  width:24%;
  font-size:14px;
}

.tsep-cvv2Div #tsep-cvv2::placeholder {
  color: transparent;
}

#tsep-cardHolderName {
  border: none; /* Remove the default border */
  border-bottom: 1px solid white; /* Add a 1px solid black border at the bottom */
  background: transparent; /* Make the background transparent */
  outline: none;
  color: white !important;
}

#tsep-cardHolderName::placeholder {
  color: white;
}

#tsep-zipCode {
  border: none; /* Remove the default border */
  border-bottom: 1px solid white; /* Add a 1px solid black border at the bottom */
  background: transparent; /* Make the background transparent */
  outline: none;
  color: white !important;
  width: '100%';
}

#tsep-zipCode::placeholder {
  color: transparent;
}
